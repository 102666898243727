<template>
  <div id="ReportList">
    <br />
    <section class="hero is-light" id="Home">
      <div class="hero-body">
        <h2 class="title">Reports</h2>
        <div>
          <select v-model="selectedReport" @change="reportSelect()">
            <option value="">Select A Report</option>
            <option
              v-for="(value, key, index) in reportsList"
              :value="key"
              :key="index"
            >
              {{ key }}
            </option>
          </select>
          <br />
          <div
            v-if="
              selectedReport != '' && reportsList[selectedReport].length > 0
            "
          >
            <div
              style="width: 200px; float: left; margin: 5px"
              v-for="(value, index) in reportsList[selectedReport]"
              :key="index"
            >
              <span>{{ value.Name }}:</span>
              <datepicker
                v-if="value.Type == 'time.Time'"
                v-model="parameters[index]"
                input-class="datePicker"
                format="yyyy-MM-dd"
              ></datepicker>
              <input
                v-if="value.Type == 'string' || value.Type == 'int'"
                type="text"
                v-model="parameters[index]"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button @click="runReport()">Run Report</button>
      </div>
      <div style="padding: 15px">
        <table id="resultsTable star">
          <thead>
            <tr v-if="report.length > 0">
              <td v-for="value in report[0]" :key="value">{{ value }}</td>
            </tr>
          </thead>
          <tbody v-if="index > 0 && report.length > 0">
            <tr v-for="(value, index) in report" :key="index">
              <td v-for="subvalue in value" :key="subvalue">{{ subvalue }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "ReportList",
  data() {
    return {
      reportsList: [],
      selectedReport: "",
      parameters: [],
      report: []
    };
  },
  methods: {
    async getReportsList() {
       
      this.$root.$emit("setLoading", true);
      await this.$api
        .reportList()
        .then(msg => {
          this.$root.$emit("setLoading", false);
         this.reportsList = msg;
        })
        .catch(() => {
          this.$root.$emit("setLoading", false);

          this.$store.dispatch("setGlobalAlertState", {
            title: "Error",
            description:
              "There was an issue while loading the reports, please try again.",
            icon: "error"
          });
        });
    },
    reportSelect() {
      this.parameters = [];
    },
    async runReport() {
      this.$root.$emit("setLoading", true);
      await this.$api
        .runReport(this.selectedReport, this.parameters)
        .then(() => {
          this.$root.$emit("setLoading", false);
        })
        .catch(() => {
          this.$root.$emit("setLoading", false);
          this.$store.dispatch("setGlobalAlertState", {
            title: "Error",
            description:
              "There was an issue while loading the reports, please try again.",
            icon: "error"
          });
        });
    }
  },
  mounted() {
    this.getReportsList();
  }
};
</script>
<style>
.datePicker {
  background-color: white;
  cursor: pointer;
  width: 200px !important;
}
.vdp-datepicker {
  display: inline !important;
}
.vdp-datepicker {
  display: inline !important;
}
</style>
